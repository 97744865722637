class Wall {
  defaultWall;
  klEvents;
  type;
  wallId;

  populate(params) {
    this.defaultWall = params.defaultWall;
    this.klEvents = params.klEvents;
    this.type = params.type;
    this.wallId = params.wallId;
  }
}

export default Wall;
