import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { getBusinessUrl } from "../../services/utils";

import charityDefaultCover from "../../images/charity-default-cover.jpg";
import businessDefaultLogo from "../../images/business-default-logo.png";

class Info extends Component {
  render() {
    const { business } = this.props;
    let coverPhoto = business.coverPhoto
      ? business.coverPhoto
      : charityDefaultCover;
    let logoPhoto = business.logoPhoto
      ? business.logoPaths.thumbs["100x100"]
      : businessDefaultLogo;
    return (
      <a
        href={getBusinessUrl(business.uri)}
        title={business.companyName}
        className="klw-profile--cover-image"
        style={{ backgroundImage: "url('" + coverPhoto + "')" }}
      >
        <img
          className="klw-profile--logo-image"
          src={logoPhoto}
          width="60"
          height="60"
          alt={business.companyName}
        />
        <span className="klw-profile--company-name">
          {business.companyName}
        </span>
      </a>
    );
  }
}

Info.propTypes = {
  business: PropTypes.object.isRequired,
};

export default Info;
