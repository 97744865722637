import * as actions from "../../actions";

export default (state = [], action) => {
  switch (action.type) {
    case actions.CONFIG_FILE[actions.FAILURE]:
      console.log("CONFIG FILE ERROR");
      break;
    case actions.CONFIG_FILE[actions.SUCCESS]:
      if (typeof state.config === "undefined") {
        return Object.assign({}, state, { config: action.response });
      }
      return state;
    case actions.PUBLIC_TOKEN[actions.FAILURE]:
      console.log("PUBLIC_TOKEN FILE ERROR");
      break;
    case actions.PUBLIC_TOKEN[actions.SUCCESS]:
      if (typeof state.token === "undefined") {
        return Object.assign({}, state, {
          token: action.response.access_token,
        });
      }
      return state;
    case actions.WIDGET_INFO[actions.FAILURE]:
      console.log("WIDGET INFO ERROR");
      break;
    case actions.WIDGET_INFO[actions.SUCCESS]:
      if (typeof state.widget === "undefined") {
        return Object.assign({}, state, { widget: action.response.body });
      }
      return state;
    default:
      return state;
  }
};
