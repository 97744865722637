import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const configFile = {
  request: (params) =>
    partialsActions.action(types.CONFIG_FILE[types.REQUEST], { params }),
  success: (params, response) =>
    partialsActions.action(types.CONFIG_FILE[types.SUCCESS], {
      params,
      response,
    }),
  failure: (params, error) =>
    partialsActions.action(types.CONFIG_FILE[types.FAILURE], { params, error }),
};

export const publicToken = {
  request: (params) =>
    partialsActions.action(types.PUBLIC_TOKEN[types.REQUEST], { params }),
  success: (params, response) =>
    partialsActions.action(types.PUBLIC_TOKEN[types.SUCCESS], {
      params,
      response,
    }),
  failure: (params, error) =>
    partialsActions.action(types.PUBLIC_TOKEN[types.FAILURE], {
      params,
      error,
    }),
};
export const widgetInfo = {
  request: (params) =>
    partialsActions.action(types.WIDGET_INFO[types.REQUEST], { params }),
  success: (params, response) =>
    partialsActions.action(types.WIDGET_INFO[types.SUCCESS], {
      params,
      response,
    }),
  failure: (params, error) =>
    partialsActions.action(types.WIDGET_INFO[types.FAILURE], { params, error }),
};

export const loadConfigFile = () =>
  partialsActions.action(types.LOAD_CONFIG_FILE, {});
export const loadPublicToken = () =>
  partialsActions.action(types.LOAD_PUBLIC_TOKEN, {});
export const loadWidgetInfo = (publicId) =>
  partialsActions.action(types.LOAD_WIDGET_INFO, { publicId });

export * from "./types";
