import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { getStatisticUnit, valueWithCurrency } from "../../services/utils";
import Parser from "html-react-parser";

class Statistics extends Component {
  render() {
    const { business } = this.props;
    return (
      <div className="klw-profile--padding">
        <select
          className="klw-profile--stats-filter"
          id="companyStatsFilter"
          disabled={true}
        >
          <option value="alltime">All time stats</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
        </select>
        <table className="klw-profile--stats-table u-full-width">
          <thead>
            <tr>{this.renderHeaders(business)}</tr>
          </thead>
          <tbody>
            <tr>{this.renderData(business)}</tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderData(business) {
    return business.statistics.map((stat) => {
      const value = Math.ceil(stat.value);
      return (
        stat.value > 0 && (
          <td key={stat.id}>
            {Parser(
              stat.currency
                ? valueWithCurrency(value, stat.currency, true)
                : value + " "
            )}
            <span className="unit">{getStatisticUnit(stat)}</span>
          </td>
        )
      );
    });
  }

  renderHeaders(business) {
    return business.statistics.map((stat) => {
      return stat.value > 0 && <th key={stat.id}>{stat.label}</th>;
    });
  }
}

Statistics.propTypes = {
  business: PropTypes.object.isRequired,
};

export default Statistics;
