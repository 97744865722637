import CONFIG from "../config";
import CONSTANTS from "./constants";

export const getCampaignListUrl = function (businessUri) {
  let url = CONFIG.PUBLIC_PAGES.HOST + "/business/{businessUri}/campaigns";

  return url.replace("{businessUri}", businessUri);
};
export const getCharitiesListUrl = function (businessUri) {
  let url = CONFIG.PUBLIC_PAGES.HOST + "/business/{businessUri}/charities";

  return url.replace("{businessUri}", businessUri);
};
export const getCampaignUrl = function (businessUri, campaignUri) {
  let url =
    CONFIG.PUBLIC_PAGES.HOST +
    "/business/{businessUri}/campaigns/{campaignUri}";

  return url
    .replace("{businessUri}", businessUri)
    .replace("{campaignUri}", campaignUri);
};
export const getBusinessUrl = function (businessUri) {
  let url = CONFIG.PUBLIC_PAGES.HOST + "/business/{businessUri}";

  return url.replace("{businessUri}", businessUri);
};
export const getCharityUrl = function (charityUri) {
  let url = CONFIG.PUBLIC_PAGES.HOST + "/charity/{charityUri}/profile";

  return url.replace("{charityUri}", charityUri);
};

export const valueWithCurrency = function (
  value,
  isoCode,
  isDependingOnResolution
) {
  if (!value) {
    value = 0;
  }

  value = value || 0;
  isoCode = isoCode || "GBP";
  isDependingOnResolution = isDependingOnResolution || false;
  isoCode = isoCode.toUpperCase();

  let prefix = CONSTANTS.CURRENCIES[isoCode];
  if (typeof prefix === "undefined") {
    prefix = "";
  }
  let result;
  if (isDependingOnResolution) {
    result = setValueDependingOnResolution(value);
  } else {
    result = value.toLocaleString();
  }

  return prefix + result;
};

function setValueDependingOnResolution(value) {
  let windowsize = window.innerWidth;
  if (windowsize < 589) {
    value = value.toString();
    if (value.length > 6) {
      value = value.slice(0, -6) + "M";
    } else if (value.length > 3) {
      value = value.slice(0, -3) + "K";
    }
  } else {
    value = value.toLocaleString();
  }

  return value;
}

export const valueWithUnit = function (value, unit) {
  if (!value) {
    value = 0;
  }

  value = value || 0;
  unit = unit || "";

  let suffix = CONSTANTS.CAMPAIGN.UNITS[unit];
  if (typeof suffix === "undefined") {
    suffix = "";
  }

  let result = value.toLocaleString();

  return result + " " + suffix;
};

export const getStatisticUnit = (statistic) => {
  if (!statistic.type || statistic.currency) {
    return "";
  }

  let unit = CONSTANTS.PROFILE.STAT.UNITS[statistic.type];

  if (typeof unit === "undefined") {
    return "";
  }

  return unit;
};

export const getPercent = function (raised, target) {
  raised = raised ? Number(raised) : 0;
  target = target ? Number(target) : 0;
  if (!target) {
    return 0;
  }
  return Math.floor((raised / target) * 100);
};
