import Requester from "./requester.service";

// api services
const addresses = {
  loadCharitiesList: "/business/{businessUri}/charity",
};
export const loadCharitiesList = function (businessUri) {
  let req = new Requester();
  let link = addresses.loadCharitiesList.replace("{businessUri}", businessUri);
  return req.get(link).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("CHARIITIES LIST REDUCER FAILURE");
      console.error(error);

      return { error };
    }
  );
};
