import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CampaignsList from "./components/campaigns-list/CampaignList";
import CharitiesList from "./components/charities-list/CharitiesList";
import CampaignView from "./components/campaign/CampaignView";
import Profile from "./components/profile/Profile";
import DonationFormRedirect from "./components/common/DonationFormRedirect";
import Landing from "./landing";

const routesList = [
  { path: "/widgets/:type/:id", component: Landing, inheritPath: true },
  { path: "/business/:businessUri", component: Profile, inheritPath: false },
  {
    path: "/business/:businessUri/charity",
    component: CharitiesList,
    inheritPath: false,
  },
  {
    path: "/business/:businessUri/campaigns",
    component: CampaignsList,
    inheritPath: false,
  },
  {
    path: "/business/:businessUri/campaigns/:campaignUri",
    component: CampaignView,
    inheritPath: false,
  },
  {
    path: "/donation-form-redirect",
    component: DonationFormRedirect,
    inheritPath: false,
  },
];

const Routes = (props) => {
  return (
    <Router>
      <div>
        {routesList.map((route) => (
          <Route
            exact={!route.inheritPath}
            key={route.path}
            path={route.path}
            component={route.component}
          />
        ))}
      </div>
    </Router>
  );
};

export default Routes;
