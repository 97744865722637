import initialLoad from "./initial/initial-load-reducer";
import campaignsList from "./campaigns-list/campaigns-list-reducer";
import charitiesList from "./charities-list/charities-list-reducer";
import campaignReducer from "./campaign/campaign-reducer";
import profile from "./profile/profile-reducer";
import contributionReducer from "./contribution/contribution-reducer";
import klevents from "./klevents/klevents-reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  campaignsList,
  charitiesList,
  contributionReducer,
  campaignReducer,
  initialLoad,
  profile,
  klevents,
});

export default rootReducer;
