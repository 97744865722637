import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Kindwall from "./Kindwall";
import Contributions from "./Contributions";

class Walls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "kindwall",
    };
  }

  changeTab(selectedTab) {
    this.setState({ tab: selectedTab });
  }

  render() {
    const { business } = this.props;

    return (
      <div className="klw-profile--padding">
        <ul className="klw-profile--kindwall-filter">
          <li>
            <span
              className={this.state.tab === "kindwall" ? "active" : ""}
              onClick={() => this.changeTab("kindwall")}
            >
              KindWall
            </span>
          </li>
          <li>
            <span
              className={this.state.tab === "contributions" ? "active" : ""}
              onClick={() => this.changeTab("contributions")}
            >
              Contributions
            </span>
          </li>
        </ul>
        {this.state.tab === "kindwall" && <Kindwall business={business} />}
        {this.state.tab === "contributions" && (
          <Contributions business={business} />
        )}
      </div>
    );
  }
}

Walls.propTypes = {
  business: PropTypes.object.isRequired,
};

export default Walls;
