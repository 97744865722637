import React from "react";
import Config from "../../config";
import { PropTypes } from "prop-types";

const geolocationView = (props) => {
  const geolocation = { ...props.geolocation };
  if (!geolocation) {
    return;
  }

  const baseUrl =
    "https://www.google.com/maps/embed/v1/place?key=" +
    Config.MAPS.EMBED_API_KEY;
  let query = "&q=";
  if (geolocation.placeId) {
    query = query + "place_id:" + geolocation.placeId;
  } else if (geolocation.formattedAddress) {
    query = query + geolocation.formattedAddress;
  } else {
    query = query + geolocation.latitude + "," + geolocation.longitude;
  }

  const constructedUrl = baseUrl + query;

  return (
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      title={geolocation.id}
      src={constructedUrl}
    ></iframe>
  );
};

geolocationView.propTypes = {
  geolocation: PropTypes.object.isRequired,
};

export default geolocationView;
