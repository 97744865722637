import * as partials from "../partials/types";

export const CONFIG_FILE = partials.createRequestTypes("CONFIG_FILE");
export const PUBLIC_TOKEN = partials.createRequestTypes("PUBLIC_TOKEN");
export const WIDGET_INFO = partials.createRequestTypes("WIDGET_INFO");
export const LOAD_CONFIG_FILE = "LOAD_CONFIG_FILE";
export const LOAD_PUBLIC_TOKEN = "LOAD_PUBLIC_TOKEN";
export const LOAD_WIDGET_INFO = "LOAD_WIDGET_INFO";

export * from "../partials/types";
