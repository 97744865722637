import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const klevents = {
  request: (params) =>
    partialsActions.action(types.KLEVENTS[types.REQUEST], { params }),
  success: (params, response) =>
    partialsActions.action(types.KLEVENTS[types.SUCCESS], {
      params,
      response,
    }),
  failure: (params, error) =>
    partialsActions.action(types.KLEVENTS[types.FAILURE], { params, error }),
};

export const loadkleventsList = (params) =>
  partialsActions.action(types.LOAD_KLEVENTS_LIST, {
    params,
  });

export * from "./types";
