import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { connect } from "react-redux";
import CONSTANTS from "../../services/constants";
import Loader from "../common/Loader";
import {
  getCampaignUrl,
  getCharityUrl,
  getPercent,
  valueWithCurrency,
  valueWithUnit,
} from "../../services/utils";
import campaignCoverPhoto from "../../images/default-campaign-image.png";
import charityLogo from "../../images/charity-default-logo.png";

class CampaignView extends Component {
  componentWillMount() {
    this.props.actions.loadCampaign(this.props.match.params.campaignUri);
  }

  render() {
    const { campaign } = this.props;
    if (typeof campaign === "undefined" || !campaign || !campaign.business) {
      return <Loader />;
    }

    let coverPhoto = campaign.coverPhoto || campaignCoverPhoto;
    let campaignInfo = {
      name: campaign.campaignName,
      url: getCampaignUrl(campaign.business.uri, campaign.uri),
      coverPhotoAsBackground: {
        backgroundImage: "url(" + coverPhoto + ")",
      },
      hasCharity: campaign.charities.length > 0,
    };
    if (campaignInfo.hasCharity) {
      campaignInfo.charity = {
        url: getCharityUrl(campaign.charities[0].charityPublicId),
        logo: campaign.charities[0].logoUrl || charityLogo,
        name: campaign.charities[0].name,
      };
    }

    let target;
    let successPercentage = getPercent(
      campaign.raisedAmount,
      campaign.targetValue
    );
    if (campaign.focus === "Money") {
      target = valueWithCurrency(campaign.targetValue, campaign.currency);
    } else {
      target = valueWithUnit(campaign.targetValue, campaign.focus);
    }
    campaignInfo.target = target;
    campaignInfo.successPercentage = successPercentage;

    if (CONSTANTS.CAMPAIGN.STATUS.ACTIVE === campaign["status"]) {
      return this.renderActiveCampaign(campaignInfo);
    }
    return this.renderFinishedCampaign(campaignInfo);
  }

  renderActiveCampaign(campaignInfo) {
    return (
      <div className="full sm-sm-full column">
        <div className="klw--s-camp">
          <a
            className="klw--s-camp--image"
            title={campaignInfo.campaignName}
            href={campaignInfo.url}
            style={campaignInfo.coverPhotoAsBackground}
          >
            {" "}
          </a>
          <div className="widget-action--buttons">
            {this.showDonationButton() ? (
              <button
                onClick={(e) => {
                  this.openDonationForm();
                }}
                className="button button-secondary button-lg button-shadow"
              >
                Donate
              </button>
            ) : null}
            {this.showGetInvolvedButton() ? (
              <a
                href={campaignInfo.url}
                target="_parent"
                className="button get-involved-button button-lg button-shadow"
              >
                Get Involved
              </a>
            ) : null}
          </div>
          <div
            className={
              "klw--s-camp--details" +
              (!campaignInfo.hasCharity ? " klw--s-camp--details-no-logo" : "")
            }
          >
            {campaignInfo.hasCharity && (
              <a className="klw--s-camp--clogo" href={campaignInfo.charity.url}>
                <img
                  src={campaignInfo.charity.logo}
                  width="40"
                  height="40"
                  alt={campaignInfo.charity.name}
                  title={campaignInfo.charity.name}
                ></img>
              </a>
            )}
            <div className="klw--s-camp--ctitle">
              <a href={campaignInfo.url} title={campaignInfo.name}>
                {campaignInfo.name}
              </a>
            </div>
            <div className="klw--s-camp--cstatus">
              <div className="klw--s-camp--cstatus-col">
                <div
                  className="klw--s-camp-progress"
                  title="Actively Fundraising, @@campaignProgress% funded of @@campaignTarget Target Goal"
                >
                  <div
                    className="klw--s-camp-progress-bar"
                    role="progressbar"
                    aria-valuenow={campaignInfo.successPercentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: campaignInfo.successPercentage + "%" }}
                  ></div>
                </div>
              </div>
              <div className="klw--s-camp--cstatus-col">
                <span
                  dangerouslySetInnerHTML={{ __html: campaignInfo.target }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFinishedCampaign(campaignInfo) {
    return (
      <div className="full sm-full column">
        <div className="klw--s-camp">
          <a
            className="klw--s-camp--image"
            title={campaignInfo.name}
            href={campaignInfo.url}
            style={campaignInfo.coverPhotoAsBackground}
          >
            {" "}
          </a>
          <div
            className={
              "klw--s-camp--details" +
              (!campaignInfo.hasCharity ? " klw--s-camp--details-no-logo" : "")
            }
          >
            {campaignInfo.hasCharity && (
              <a className="klw--s-camp--clogo" href={campaignInfo.charity.url}>
                <img
                  src={campaignInfo.charity.logo}
                  width="40"
                  height="40"
                  alt={campaignInfo.charity.name}
                  title={campaignInfo.charity.name}
                ></img>
              </a>
            )}
            <div className="klw--s-camp--ctitle">
              <a href={campaignInfo.url} title={campaignInfo.name}>
                {campaignInfo.name}
              </a>
            </div>
            <div className="klw--s-camp--cstatus">
              <div className="klw--s-camp--cstatus-col">
                Target:{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: campaignInfo.target }}
                />
              </div>
              <div className="klw--s-camp--cstatus-col">
                Success: {campaignInfo.successPercentage}%
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openDonationForm() {
    const { campaign } = this.props;
    let url = getCampaignUrl(campaign.business.uri, campaign.uri);
    url += "/donationform";

    window.open(url, "_blank");
  }

  showDonationButton() {
    const { campaign } = this.props;

    if (
      campaign.charities &&
      campaign.charities.length === 1 &&
      (campaign.status === "Active" || campaign.status === "Finished") &&
      campaign.focus &&
      campaign.targetValue &&
      campaign.description &&
      campaign.fundraising
    ) {
      return true;
    }

    return false;
  }

  showGetInvolvedButton() {
    const { campaign } = this.props;

    if (
      (campaign.status === "Active" || campaign.status === "Finished") &&
      campaign.manageParticipation
    ) {
      return true;
    }

    return false;
  }
}

CampaignView.propTypes = {
  campaign: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignReducer,
    contribution: state.contributionReducer.contribution,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CampaignView);
