import * as actions from "../../actions";
import Campaign from "../../models/Campaign";

export default (state = [], action) => {
  switch (action.type) {
    case actions.CAMPAIGN_VIEW[actions.FAILURE]:
      console.log("CAMPAINS_LIST ERROR");
      break;
    case actions.CAMPAIGN_VIEW[actions.SUCCESS]:
      if (typeof state.campaign === "undefined") {
        let campaign = new Campaign();
        campaign.populate(action.response.body);
        return Object.assign({}, state, campaign);
      }
      return state;
    default:
      return Object.assign({}, state, {});
  }
};
