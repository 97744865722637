import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const campaignsList = {
  request: (businessUri) =>
    partialsActions.action(types.CAMPAINS_LIST[types.REQUEST], { businessUri }),
  success: (businessUri, response) =>
    partialsActions.action(types.CAMPAINS_LIST[types.SUCCESS], {
      businessUri,
      response,
    }),
  failure: (businessUri, error) =>
    partialsActions.action(types.CAMPAINS_LIST[types.FAILURE], {
      businessUri,
      error,
    }),
};

export const loadCampaignsListPage = (businessUri, requiredFields = []) =>
  partialsActions.action(types.LOAD_CAMPAINS_LIST_PAGE, {
    businessUri,
    requiredFields,
  });

export const contribution = {
  request: (campaignUri, donation) =>
    partialsActions.action(types.CONTRIBUTION[types.REQUEST], {
      campaignUri,
      donation,
    }),
  success: ({ campaignUri, donation }, response) =>
    partialsActions.action(types.CONTRIBUTION[types.SUCCESS], {
      campaignUri,
      donation,
      response,
    }),
  failure: ({ campaignUri, donation }, error) =>
    partialsActions.action(types.CONTRIBUTION[types.FAILURE], {
      campaignUri,
      donation,
      error,
    }),
};

export const createContributionFromDonation = (
  campaignUri,
  donation,
  requiredFields = []
) =>
  partialsActions.action(types.CREATE_CONTRIBUTION, {
    campaignUri,
    donation,
    requiredFields,
  });

export * from "./types";
