import Resource from "./Resource";

class Placeholder {
  id;
  klEvent;
  name;
  resources;

  populate(params) {
    if (params.resources && params.resources.length) {
      let resources = [];
      for (let i = 0; i < params.resources.length; i++) {
        let resource = params.resources[i];
        if (!params.resources[i] instanceof Resource) {
          resource = new Resource();
          resource.populate(params.resources[i]);
        }
        resources.push(resource);
      }
      this.resources = resources;
    } else {
      this.resources = params.resources;
    }

    this.id = params.id;
    this.klEvent = params.klEvent;
    this.name = params.name;
  }
}

export default Placeholder;
