import React, { Component } from "react";
import { PropTypes } from "prop-types";
import SharingUtils from "../../services/sharing-utils";
import CONSTANTS from "../../services/constants";

class Buttons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.shareUtils = new SharingUtils(CONSTANTS.SHARING.CONTEXTS.KLEVENT);
  }

  toggleShareMenu() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  getShareLink(network) {
    const { shareParams } = this.props;

    return this.shareUtils.getLink(network, shareParams);
  }

  openLink(link) {
    window.open(link);
  }

  doCopy() {
    let link = this.getShareLink(CONSTANTS.SHARING.NETWORKS.LINK);
    this.shareUtils.copyToClipboard(link);
  }

  render() {
    const { shareParams } = this.props;
    let facebook = this.getShareLink(CONSTANTS.SHARING.NETWORKS.FACEBOOK);
    let twitter = this.getShareLink(CONSTANTS.SHARING.NETWORKS.TWITTER);
    let linkedin = this.getShareLink(CONSTANTS.SHARING.NETWORKS.LINKEDIN);
    let email = this.getShareLink(CONSTANTS.SHARING.NETWORKS.EMAIL);

    let options = {
      selector: "yammer-manual-post-" + shareParams.kleventId,
    };
    this.shareUtils.initCustomShares(
      CONSTANTS.SHARING.NETWORKS.YAMMER,
      shareParams,
      options
    );

    return (
      <div
        className={
          "dropdown--button--share-klevent klevent " +
          (this.state.active ? "show" : "")
        }
        onClick={this.toggleShareMenu.bind(this)}
      >
        {/*<button className="button buttons--klevent">Donate</button>*/}
        <button className="button buttons--klevent">Share</button>

        <div className="dropdown-menu nowrap">
          <span
            className="dropdown-item link-icon-facebook"
            onClick={() => this.openLink(facebook)}
          ></span>
          <span
            className="dropdown-item link-icon-twitter"
            onClick={() => this.openLink(twitter)}
          ></span>
          <span
            className="dropdown-item link-icon-linkedin"
            onClick={() => this.openLink(linkedin)}
          ></span>
          <span
            className={
              "dropdown-item link-icon-yammer yammer-manual-post-" +
              shareParams.kleventId
            }
          ></span>
          <span
            className="dropdown-item link-icon-email"
            onClick={() => this.openLink(email)}
          ></span>
          <span
            className="dropdown-item link-icon-copy"
            onClick={() => this.doCopy()}
          ></span>
        </div>
      </div>
    );
  }
}

Buttons.propTypes = {
  shareParams: PropTypes.object.isRequired,
};

export default Buttons;
