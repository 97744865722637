class User {
  address;
  createdDate;
  email;
  firstName;
  lastName;
  permissions;
  phoneNumber;
  profilePictureUrl;
  updatedDate;
  userId;
  uuid;

  populate(params) {
    this.address = params.address;
    this.createdDate = params.createdDate;
    this.email = params.email;
    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.permissions = params.permissions;
    this.phoneNumber = params.phoneNumber;
    this.profilePictureUrl = params.profilePictureUrl;
    this.updatedDate = params.updatedDate;
    this.userId = params.userId;
    this.uuid = params.uuid;
  }
}

export default User;
