import * as actions from "../../actions";
import Business from "../../models/Business";

export default (state = [], action) => {
  switch (action.type) {
    case actions.PROFILE[actions.FAILURE]:
      return Object.assign({}, state, {
        errors: action.error.response.data.errors,
      });
    case actions.PROFILE[actions.SUCCESS]:
      if (typeof state.profile === "undefined") {
        let business = new Business();
        business.populate(action.response.body);
        return Object.assign({}, state, { info: business });
      }
      return state;
    default:
      return state;
  }
};
