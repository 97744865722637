import * as actions from "../../actions";
import Charity from "../../models/Charity";

export default (state = [], action) => {
  switch (action.type) {
    case actions.CHARITIES_LIST[actions.FAILURE]:
      console.log("CHARITIES_LIST ERROR");
      break;
    case actions.CHARITIES_LIST[actions.SUCCESS]:
      if (typeof state.pageCharities === "undefined") {
        let pageCharities = action.response.body.profileCharities.map(
          (current) => {
            let charity = new Charity();
            charity.populate(current);
            return charity;
          }
        );
        let subCharities = action.response.body.subProfilesCharities.map(
          (current) => {
            let charity = new Charity();
            charity.populate(current);
            return charity;
          }
        );

        return Object.assign({}, state, {
          pageCharities: pageCharities,
          subCharities: subCharities,
        });
      }
      return state;
    default:
      return state;
  }
};
