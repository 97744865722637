import BusinessCategory from "./BusinessCategory";
import Wall from "./Wall";

class Business {
  active;
  address;
  businessCategories;
  color;
  companyName;
  companyRegistrationNumber;
  coverPhoto;
  currency;
  description;
  donatedValue;
  editable;
  email;
  inKindValue;
  isPublic;
  logoPaths;
  logoPhoto;
  owner;
  pageId;
  phone;
  public;
  raisedValue;
  socialNetworks;
  story;
  uri;
  uuid;
  volunteeredHours;
  walls;
  website;
  statistics;

  populate(params) {
    if (params.businessCategories && params.businessCategories.length) {
      let businessCategories = [];
      for (let i = 0; i < params.businessCategories.length; i++) {
        let category = params.businessCategories[i];
        if (!params.businessCategories[i] instanceof BusinessCategory) {
          category = new BusinessCategory();
          category.populate(params.businessCategories[i]);
        }
        businessCategories.push(category);
      }
      this.businessCategories = businessCategories;
    } else {
      this.businessCategories = params.businessCategories;
    }
    if (params.walls && params.walls.length) {
      let walls = [];
      for (let i = 0; i < params.walls.length; i++) {
        let wall = params.walls[i];
        if (!params.walls[i] instanceof Wall) {
          wall = new Wall();
          wall.populate(params.walls[i]);
        }
        walls.push(wall);
      }
      this.walls = walls;
    } else {
      this.walls = params.walls;
    }

    this.active = params.active;
    this.address = params.address;
    this.color = params.color;
    this.companyName = params.companyName;
    this.companyRegistrationNumber = params.companyRegistrationNumber;
    this.coverPhoto = params.coverPhoto;
    this.currency = params.currency;
    this.description = params.description;
    this.editable = params.editable;
    this.email = params.email;
    this.isPublic = params.isPublic;
    this.logoPaths = params.logoPaths;
    this.logoPhoto = params.logoPhoto;
    this.owner = params.owner;
    this.pageId = params.pageId;
    this.phone = params.phone;
    this.public = params.public;
    this.website = params.website;
    this.socialNetworks = params.socialNetworks;
    this.story = params.story;
    this.uri = params.uri;
    this.uuid = params.uuid;
    this.statistics = params.statistics;
  }
}

export default Business;
