import { all, call, fork, put, select, take } from "redux-saga/effects";
import * as actions from "../actions";
import { loadCampaignsList } from "../services/campaigns-list-service";
import { loadCampaignView } from "../services/campaigns-list-service";
import { loadCharitiesList } from "../services/charities-list-service";
import { createContributionFromDonation } from "../services/contribution-service";
import { loadProfile } from "../services/profile";
import { loadKlevents } from "../services/klevents";
import {
  loadConfig,
  loadPublicToken,
  loadWidgetInfo,
} from "../services/initial-load";
import {
  getConfigSelector,
  getPublicTokenSelector,
} from "../reducers/selectors";

// each entity defines 3 creators { request, success, failure }
const {
  campaignsList,
  charitiesList,
  campaignView,
  contribution,
  configFile,
  publicToken,
  loadConfigFile,
  widgetInfo,
  profile,
  klevents,
} = actions;

/******************************************************************************/
/******************************* GENERATORS *************************************/
export const getState = (state) => state;

function* fetchEntity(entity, apiFn, params) {
  yield put(entity.request(params));
  const { response, error } = yield call(apiFn, params);
  if (response) {
    yield put(entity.success(params, response));
  } else {
    yield put(entity.failure(params, error));
  }
}

/******************************************************************************/
export const fetchCampaignsList = fetchEntity.bind(
  null,
  campaignsList,
  loadCampaignsList
);
export const fetchCharitiesList = fetchEntity.bind(
  null,
  charitiesList,
  loadCharitiesList
);
export const fetchCampaignView = fetchEntity.bind(
  null,
  campaignView,
  loadCampaignView
);
export const fetchContribution = fetchEntity.bind(
  null,
  contribution,
  createContributionFromDonation
);
export const fetchProfile = fetchEntity.bind(null, profile, loadProfile);
export const fetchKlevents = fetchEntity.bind(null, klevents, loadKlevents);
export const fetchWidgetInfo = fetchEntity.bind(
  null,
  widgetInfo,
  loadWidgetInfo
);
export const fetchConfigFile = fetchEntity.bind(null, configFile, loadConfig);
export const fetchPublicToken = fetchEntity.bind(
  null,
  publicToken,
  loadPublicToken
);

// load config unless it is cached
function* getConfig() {
  const config = yield select(getConfigSelector);
  if (!config) {
    yield call(fetchConfigFile, {});
  }
}

// load public token unless it is cached
function* getPublicToken() {
  const token = yield select(getPublicTokenSelector);
  if (!token) {
    yield call(fetchPublicToken, {});
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/
function* watchLoadCampaign() {
  const { campaignUri } = yield take(actions.LOAD_CAMPAIGN_VIEW);
  let state = yield select(getState);
  if (!state.initialLoad.token || !state.initialLoad.config) {
    yield put(loadConfigFile());
    yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
  }
  yield call(fetchCampaignView, campaignUri);
}

function* watchLoadCampaignsListPage() {
  while (true) {
    const { businessUri } = yield take(actions.LOAD_CAMPAINS_LIST_PAGE);
    let state = yield select(getState);
    if (!state.initialLoad.token || !state.initialLoad.config) {
      yield put(loadConfigFile());
      yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchCampaignsList, businessUri);
  }
}

function* watchLoadCharitiesListPage() {
  while (true) {
    const { businessUri } = yield take(actions.LOAD_CHARITIES_LIST_PAGE);
    let state = yield select(getState);
    if (!state.initialLoad.token || !state.initialLoad.config) {
      yield put(loadConfigFile());
      yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchCharitiesList, businessUri);
  }
}

function* watchCreateContribution() {
  while (true) {
    const { campaignUri, donation } = yield take(actions.CREATE_CONTRIBUTION);
    let state = yield select(getState);
    if (!state.initialLoad.token || !state.initialLoad.config) {
      yield put(loadConfigFile());
      yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchContribution, { campaignUri, donation });
  }
}

function* watchLoadProfilePage() {
  while (true) {
    const { businessUri } = yield take(actions.LOAD_PROFILE_PAGE);
    let state = yield select(getState);
    if (!state.initialLoad.token || !state.initialLoad.config) {
      yield put(loadConfigFile());
      yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchProfile, businessUri);
  }
}

function* watchLoadKleventsList() {
  while (true) {
    const { params } = yield take(actions.LOAD_KLEVENTS_LIST);
    let state = yield select(getState);
    if (!state.initialLoad.token || !state.initialLoad.config) {
      yield put(loadConfigFile());
      yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchKlevents, params);
  }
}

function* watchLoadWidgetInfoPage() {
  while (true) {
    const { publicId } = yield take(actions.LOAD_WIDGET_INFO);
    let state = yield select(getState);
    if (!state.initialLoad.token || !state.initialLoad.config) {
      yield put(loadConfigFile());
      yield take(actions.PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchWidgetInfo, publicId);
  }
}

function* watchLoadConfigFile() {
  while (true) {
    yield take(actions.LOAD_CONFIG_FILE);
    yield fork(getConfig);
    yield take(actions.CONFIG_FILE[actions.SUCCESS]);
    yield fork(getPublicToken);
  }
}

export default function* root() {
  yield all([
    fork(watchLoadConfigFile),
    fork(watchLoadCampaignsListPage),
    fork(watchLoadCharitiesListPage),
    fork(watchLoadCampaign),
    fork(watchCreateContribution),
    fork(watchLoadProfilePage),
    fork(watchLoadWidgetInfoPage),
    fork(watchLoadKleventsList),
  ]);
}
