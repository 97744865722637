import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import Config from "../../config";

class DonationFormRedirect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignId: 0,
    };
  }

  componentWillMount() {
    let campaign = JSON.parse(localStorage.getItem("campaign"));
    let submitData = JSON.parse(localStorage.getItem("contribution"));

    this.props.actions.createContributionFromDonation(campaign.uri, submitData);
  }

  componentWillReceiveProps(nextProps) {
    let contribution = nextProps.contribution;

    if (typeof contribution !== "undefined") {
      let campaign = JSON.parse(localStorage.getItem("campaign"));
      let url =
        Config.DONATION_FORM.HOST +
        "/" +
        contribution.charity.charityPublicId +
        "/" +
        contribution.project.crmProjectId +
        "?";

      url += "campaign_id=" + campaign.pageId + "&";
      url += "campaign_name=" + encodeURIComponent(campaign.campaignName) + "&";
      url += "currency=" + contribution.currency + "&";
      url += "donation_id=" + contribution.id + "&";
      url += "source=" + contribution.source;
      window.location.href = url;
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contribution: state.contributionReducer.contribution,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonationFormRedirect);
