class BusinessCategory {
  businessCategoryId;
  createdDate;
  customName;
  groupable;
  name;
  pageCategoryId;
  updatedDate;

  populate(params) {
    this.businessCategoryId = params.businessCategoryId;
    this.createdDate = params.createdDate;
    this.customName = params.customName;
    this.groupable = params.groupable;
    this.name = params.name;
    this.pageCategoryId = params.pageCategoryId;
    this.updatedDate = params.updatedDate;
  }
}

export default BusinessCategory;
