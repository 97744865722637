import Config from "../config";
import Requester from "./requester.service";

export const loadConfig = function () {
  return Config.init().then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.log("Error loading config");
      return { error };
    }
  );
};
export const loadPublicToken = function () {
  let req = new Requester();
  return req.getPublicToken().then(
    function (response) {
      response = response.data;
      Config.setAccessToken(response.access_token);
      return { response };
    },
    function (error) {
      return { error };
    }
  );
};

export const loadWidgetInfo = function (publicId) {
  let url = "/widgets/{publicid}".replace("{publicid}", publicId);
  let req = new Requester();
  return req.get(url).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      return { error };
    }
  );
};
