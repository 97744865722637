import Requester from "./requester.service";

// api services
const addresses = {
  loadProfile: "/business/{businessUri}",
};
export const loadProfile = function (businessUri) {
  let req = new Requester();
  let link = addresses.loadProfile.replace("{businessUri}", businessUri);
  return req.get(link).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      return { error };
    }
  );
};
