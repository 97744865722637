class CONSTANTS {
  CAMPAIGN = {
    STATUS: {
      FINISHED: "Finished",
      ACTIVE: "Active",
    },
    UNITS: {
      Money: "Money",
      Hours: "hrs",
      Kilograms: "kg",
      KWh: "kWh",
      TonnesCO2: "t. CO2",
      Pieces: "pcs",
      SquareMeters: "sq.m.",
      SquareFoots: "sq.ft.",
      Number: "number",
      Litre: "litre",
      CubicMetersNitrogen: "m3/NO2",
      Other: "Other",
    },
    TYPES: {
      DonationInternal: "Donations: company contributions",
      DonationExternal: "Donations: fundraising & contributions",
      Equipment: "Equipment",
      OfficeSpace: "Office space",
      ProBonoWork: "Pro-Bono Work",
      Volunteering: "Volunteering",
      Other: "Other",
    },
  };
  CONTRIBUTION = {
    METHOD: {
      CARD_PAYMENT: "CreditCard",
    },
    SOURCE: {
      USER_ADDED_CONTRIBUTION: "USER_ADDED_CONTRIBUTION",
      CONTRIBUTIONS_LIST: "CONTRIBUTIONS_LIST",
      PARTICIPATIONS_LIST: "PARTICIPATIONS_LIST",
    },
  };
  CURRENCIES = {
    GBP: "&pound;",
    EUR: "&euro;",
    USD: "&dollar;",
    CAD: "&dollar;",
    JPY: "&yen;",
    NOK: "kr",
    SEK: "kr",
    CHF: "fr",
    NZD: "&dollar;",
    AUD: "&dollar;",
  };
  SHARING = {
    CONTEXTS: {
      PROFILE: "PROFILE",
      KLEVENT: "MANUAL_POST",
    },
    NETWORKS: {
      FACEBOOK: "FACEBOOK",
      TWITTER: "TWITTER",
      LINKEDIN: "LINKEDIN",
      YAMMER: "YAMMER",
      EMAIL: "EMAIL",
      LINK: "LINK",
    },
  };
  PROFILE = {
    WALLS: {
      KINDWALL: "BUSINESS_WALL",
      CONTRIBUTIONS: "BUSINESS_CONTRIBUTIONS_WALL",
    },
    STAT: {
      UNITS: {
        MONEY: "money",
        HOURS: "hours",
        HOURS_VOLUNTEERED: "hours",
        KILOGRAMS: "kg",
        KWH: "kWh",
        TONNES_CO2: "tonnes CO2",
        PIECES: "pcs",
        SQUARE_METERS: "sq. m.",
        SQUARE_FEET: "sq. ft.",
        NUMBER: "number",
        LITRE: "litre",
        CUBIC_METERS_NITROGEN: "m3/NO2",
        OTHER: "other",
      },
    },
  };
  KLEVENT = {
    CATEGORY: {
      MANUAL: "MANUAL",
      AUTOMATIC_WITHOUT_LOGOS: "AUTOMATIC_WITHOUT_LOGOS",
      AUTOMATIC_WITH_CHARITY_LOGO: "AUTOMATIC_WITH_CHARITY_LOGO",
      AUTOMATIC_WITH_CHARITY_AND_PROJECT_LOGOS:
        "AUTOMATIC_WITH_CHARITY_AND_PROJECT_LOGOS",
    },
    TYPE: {
      USER_ADDED_CONTRIBUTION: "USER_ADDED_CONTRIBUTION",
      NEW_USER_SIGNUP: "NEW_USER_SIGNUP",
      CAMPAIGN_START: "CAMPAIGN_START",
      CAMPAIGN_END: "CAMPAIGN_END",
      MANUAL_UPDATE_CAMPAIGN: "MANUAL_UPDATE_CAMPAIGN",
      CHARITY_PROJECT_UPDATE: "CHARITY_PROJECT_UPDATE",
    },
    MEDIA: {
      IMAGES: "images",
      VIDEO: "video",
    },
  };
}

export default new CONSTANTS();
