import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import CharitiesListItem from "./CharitiesListItem";
import { getCharitiesListUrl } from "../../services/utils";
import Loader from "../common/Loader";

class CharitiesList extends Component {
  DEFAULT_ITEMS_COUNT = 10;

  componentWillMount() {
    this.props.actions.loadCharitiesListPage(
      this.props.match.params.businessUri
    );
  }

  render() {
    const { pageCharities, subCharities, widget } = this.props;

    if (typeof pageCharities === "undefined" || !pageCharities) {
      return <Loader />;
    }

    let allCharities;
    if (widget && widget.extendedList) {
      allCharities = [].concat(pageCharities, subCharities);
    } else {
      allCharities = pageCharities;
    }

    // Remove duplicates
    let uniqueCharities = [];
    let idMap = new Map();
    for (let item of allCharities) {
      if (!idMap.has(item.charityId)) {
        idMap.set(item.charityId, true); // set any value to Map
        uniqueCharities.push(item);
      }
    }

    let itemsCount = this.DEFAULT_ITEMS_COUNT;
    if (widget && widget.maxItems && widget.maxItems > 0) {
      itemsCount = widget.maxItems;
    }

    let businessUri = this.props.match.params.businessUri;

    let showCharities = [];
    for (let i = 0; i < uniqueCharities.length; i++) {
      if (i === itemsCount) {
        break;
      }
      showCharities.push(uniqueCharities[i]);
    }

    return (
      <div className="container">
        <div className="row">
          {showCharities.map((charity) => (
            <CharitiesListItem
              key={charity.charityPublicId}
              charity={charity}
            />
          ))}
        </div>
        {allCharities.length > itemsCount && (
          <div className="klw-all-link">
            <a href={getCharitiesListUrl(businessUri)}>
              View all {allCharities.length} supported charities
            </a>
          </div>
        )}
      </div>
    );
  }
}

CharitiesList.propTypes = {
  pageCharities: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};

CharitiesList.defaultProps = {
  pageCharities: [],
  subCharities: [],
};

function mapStateToProps(state, ownProps) {
  return {
    pageCharities: state.charitiesList.pageCharities,
    subCharities: state.charitiesList.subCharities,
    widget: state.initialLoad.widget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CharitiesList);
