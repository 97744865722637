import React, { Component } from "react";
import * as actions from "./actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "./components/common/Loader";

class Landing extends Component {
  urls = {
    PROFILE: {
      url: "/business/{businessUri}",
      params: ["businessUri"],
    },
    CAMPAIGN_LIST: {
      url: "/business/{businessUri}/campaigns",
      params: ["businessUri"],
    },
    SUPPORTED_CHARITIES: {
      url: "/business/{businessUri}/charity",
      params: ["businessUri"],
    },
    SINGLE_CAMPAIGN: {
      url: "/business/{businessUri}/campaigns/{campaignUri}",
      params: ["businessUri", "campaignUri"],
    },
  };

  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.actions.loadWidgetInfo(id);
  }

  componentWillReceiveProps(nextProps) {
    const { widget } = nextProps;
    if (
      typeof widget !== "undefined" &&
      typeof widget.widgetType !== "undefined"
    ) {
      let params;

      if (widget.widgetType === "SINGLE_CAMPAIGN") {
        params = {
          businessUri: widget.associatedPage.uri,
          campaignUri: widget.associatedCampaign.uri,
        };
      } else {
        params = {
          businessUri: widget.associatedPage.uri,
        };
      }
      let url = this.urls[widget.widgetType].url;
      for (let i = 0; i < this.urls[widget.widgetType].params.length; i++) {
        url = url.replace(
          "{" + this.urls[widget.widgetType].params[i] + "}",
          params[this.urls[widget.widgetType].params[i]]
        );
      }

      this.props.history.push(url);
    }
  }

  render() {
    return <Loader />;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    widget: state.initialLoad.widget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
