import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const campaignView = {
  request: (campaignUri) =>
    partialsActions.action(types.CAMPAIGN_VIEW[types.REQUEST], { campaignUri }),
  success: (campaignUri, response) =>
    partialsActions.action(types.CAMPAIGN_VIEW[types.SUCCESS], {
      campaignUri,
      response,
    }),
  failure: (campaignUri, error) =>
    partialsActions.action(types.CAMPAIGN_VIEW[types.FAILURE], {
      campaignUri,
      error,
    }),
};

export const loadCampaign = (campaignUri, requiredFields = []) =>
  partialsActions.action(types.LOAD_CAMPAIGN_VIEW, {
    campaignUri,
    requiredFields,
  });

export * from "./types";
