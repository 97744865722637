import Requester from "./services/requester.service";
import base64 from "base-64";

class Config {
  static instance;
  APP_ID;
  SOCIAL;
  AUTH;
  PUBLIC_PAGES;
  DONATION_FORM;
  MAPS;

  constructor() {
    this.populate(null, this);
  }

  static getInstance() {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  init() {
    var that = Config.getInstance();
    return new Promise(function (resolve, reject) {
      Requester.getConfig("/config/config.json")
        .then(function (response) {
          that.populate(response.data, that);
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  populate(options, scope) {
    if (typeof options === "undefined" || !options) {
      options = {};
      options.SOCIAL = {};
      options.AUTH = {};
      options.PUBLIC_PAGES = {};
      options.DONATION_FORM = {};
      options.MAPS = {};
    }
    scope.APP_ID = options.APP_ID || "";
    scope.SOCIAL = {
      HOST: options.SOCIAL.HOST || "",
      PUBLIC_CLIENT_ID: options.SOCIAL.PUBLIC_CLIENT_ID || "",
      PUBLIC_CLIENT_SECRET: options.SOCIAL.PUBLIC_CLIENT_SECRET || "",
    };
    scope.AUTH = {
      HOST: options.AUTH.HOST || "",
    };

    scope.PUBLIC_PAGES = {
      HOST: options.PUBLIC_PAGES.HOST || "",
    };

    scope.DONATION_FORM = {
      HOST: options.DONATION_FORM.HOST || "",
    };

    scope.MAPS = {
      EMBED_API_KEY: options.MAPS.EMBED_API_KEY || "",
    };
  }

  setAccessToken(token, context) {
    if (typeof context === "undefined") {
      context = "SOCIAL";
    }
    this[context].ACCESS_TOKEN = token;
  }

  getAuthorizationToken() {
    if (typeof this.SOCIAL.ACCESS_TOKEN !== "undefined") {
      return "Bearer " + this.SOCIAL.ACCESS_TOKEN;
    }
    return (
      "Basic " +
      this.getAsBase64(
        this.SOCIAL.PUBLIC_CLIENT_ID,
        this.SOCIAL.PUBLIC_CLIENT_SECRET
      )
    );
  }

  getAsBase64(id, secret) {
    let originalString = id + ":" + secret;
    return base64.encode(originalString);
  }
}

export default Config.getInstance();
