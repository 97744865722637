import Config from "../config";

class SharingUtils {
  shareLinks = {
    FACEBOOK:
      "https://www.facebook.com/dialog/feed?app_id={appId}&display=popup&link={redirectUrl}&redirect_uri={redirectUrl}&source={image}",
    LINKEDIN:
      "https://www.linkedin.com/shareArticle?mini=true&url={redirectUrl}",
    TWITTER:
      "https://twitter.com/intent/tweet?text={description}&url={redirectUrl}",
    EMAIL: "mailto:?subject={title}&body={description}%0D%0A{redirectUrl}",
    LINK: "{redirectUrl}",
  };
  shareLinksOptions = {
    PROFILE: {
      appId: {
        value: Config.APP_ID,
        keys: [],
      },
      redirectUrl: {
        value: Config.PUBLIC_PAGES.HOST + "/business/{pageUri}",
        keys: ["pageUri"],
      },
      image: {
        value: "{imageUrl}",
        keys: ["imageUrl"],
        defaultImage:
          Config.PUBLIC_PAGES.HOST + "/assets/images/business-default-logo.png",
      },
      title: {
        value: "{companyName} is helping charities on KindLink",
        keys: ["companyName"],
      },
      description: {
        value:
          "See how {companyName} and its team is giving back and helping non-profit organisations",
        keys: ["companyName"],
      },
    },
    CAMPAIGN: {
      appId: {
        value: Config.APP_ID,
        keys: [],
      },
      redirectUrl: {
        value:
          Config.PUBLIC_PAGES.HOST +
          "/business/{pageUri}/campaigns/{campaignUri}",
        keys: ["pageUri", "campaignUri"],
      },
      image: {
        value: "{imageUrl}",
        keys: ["imageUrl"],
        defaultImage:
          Config.PUBLIC_PAGES.HOST +
          "/assets/images/default-campaign-image.png",
      },
      title: {
        value: "{companyName} is running a charity campaign on KindLink",
        keys: ["companyName"],
      },
      description: {
        value:
          "Support {companyName}'s efforts to help and see how they are giving back to the community",
        keys: ["companyName"],
      },
    },
    MANUAL_POST: {
      appId: {
        value: Config.APP_ID,
        keys: [],
      },
      redirectUrl: {
        value:
          Config.PUBLIC_PAGES.HOST +
          "/business/{pageUri}/walls/{wallId}/posts/{kleventId}",
        keys: ["pageUri", "wallId", "kleventId"],
      },
      image: {
        value: "{imageUrl}",
        keys: ["imageUrl"],
        defaultImage:
          Config.PUBLIC_PAGES.HOST + "/assets/images/business-default-logo.png",
      },
      title: {
        value: "{companyName} posted a new campaign update",
        keys: ["companyName"],
      },
      description: {
        value:
          "See {companyName}'s efforts in their social impact program and what their employees share on KindLink",
        keys: ["companyName"],
      },
    },
  };
  externalShareButtons = {
    YAMMER: {
      init: function (options) {
        window.yam.platform.yammerShare(options);
      },
      options: {
        customButton: true,
        classSelector: "{selector}",
        defaultMessage: "{description}",
        pageUrl: "{redirectUrl}",
      },
    },
  };

  constructor(context) {
    this.context = context;
  }

  getLink(network, params) {
    if (typeof this.shareLinksOptions[this.context] === "undefined") {
      return;
    }
    let shareLinksOptions = this.shareLinksOptions[this.context];
    let shareLink = this.shareLinks[network];

    if (typeof shareLink === "undefined") {
      return;
    }
    Object.keys(shareLinksOptions).forEach(function (shareOption) {
      let shareParam = shareLinksOptions[shareOption].value;
      if (shareLinksOptions[shareOption].keys.length) {
        for (let i = 0; i < shareLinksOptions[shareOption].keys.length; i++) {
          let paramValue = params[shareLinksOptions[shareOption].keys[i]];
          if (shareOption === "image") {
            if (!paramValue) {
              paramValue = shareLinksOptions[shareOption].defaultImage;
            } else {
              //TODO: This is probably not needed any more as most of the images have been migrated. Cleanup the next time.
              paramValue = paramValue
                .replace(
                  "https://www.googleapis.com/download/storage/v1/b/",
                  "https://storage.googleapis.com/"
                )
                .replace("/o/", "/")
                .split("?")[0];
            }
          }
          shareParam = shareParam.replace(
            new RegExp(
              "{" + shareLinksOptions[shareOption].keys[i] + "}",
              "gi"
            ),
            paramValue
          );
        }
      }
      shareLink = shareLink.replace(
        new RegExp("{" + shareOption + "}", "gi"),
        shareParam.replace(new RegExp(" ", "gi"), "%20")
      );
    });

    return shareLink;
  }

  copyToClipboard(textToCopy) {
    // Create container for the HTML
    let container = document.createElement("div");
    container.innerHTML = textToCopy;

    // Hide element
    container.style.position = "fixed";
    container.style.pointerEvents = "none";
    container.style.opacity = "0";

    // Detect all style sheets of the page
    Array.prototype.slice.call(document.styleSheets).filter(function (sheet) {
      return !sheet.disabled;
    });

    // Mount the iframe to the DOM to make `contentWindow` available
    document.body.appendChild(container);

    // Copy to clipboard
    window.getSelection().removeAllRanges();

    let range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);

    document.execCommand("copy");

    // Remove the iframe
    document.body.removeChild(container);
  }

  initCustomShares(socialNetowrk, params, options) {
    if (typeof this.externalShareButtons[socialNetowrk] === "undefined") {
      return;
    }
    if (typeof this.shareLinksOptions[this.context] === "undefined") {
      return;
    }
    let shareLinksOptions = this.shareLinksOptions[this.context];
    let shareButton = this.externalShareButtons[socialNetowrk];
    let shareButtonOptions = shareButton.options;

    Object.keys(shareLinksOptions).forEach(function (shareOption) {
      let shareParam = shareLinksOptions[shareOption].value;
      if (shareLinksOptions[shareOption].keys.length) {
        for (let i = 0; i < shareLinksOptions[shareOption].keys.length; i++) {
          let paramValue = params[shareLinksOptions[shareOption].keys[i]];
          if (shareOption === "image") {
            if (!paramValue) {
              paramValue = shareLinksOptions[shareOption].defaultImage;
            } else {
              paramValue = paramValue
                .replace(
                  "https://www.googleapis.com/download/storage/v1/b/",
                  "https://storage.googleapis.com/"
                )
                .replace("/o/", "/")
                .split("?")[0];
            }
          }
          shareParam = shareParam.replace(
            new RegExp(
              "{" + shareLinksOptions[shareOption].keys[i] + "}",
              "gi"
            ),
            paramValue
          );
        }
      }
      for (let buttonOptionsKey in shareButtonOptions) {
        if (typeof shareButtonOptions[buttonOptionsKey] === "string") {
          shareButtonOptions[buttonOptionsKey] = shareButtonOptions[
            buttonOptionsKey
          ].replace(new RegExp("{" + shareOption + "}", "gi"), shareParam);
        }
      }
    });
    for (let buttonOptionsKey in shareButtonOptions) {
      if (typeof shareButtonOptions[buttonOptionsKey] === "string") {
        for (let optionsKey in options) {
          shareButtonOptions[buttonOptionsKey] = shareButtonOptions[
            buttonOptionsKey
          ].replace(
            new RegExp("{" + optionsKey + "}", "gi"),
            options[optionsKey]
          );
        }
      }
    }
    shareButton.init(shareButtonOptions);
  }
}

export default SharingUtils;
