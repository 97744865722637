import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const charitiesList = {
  request: (businessUri) =>
    partialsActions.action(types.CHARITIES_LIST[types.REQUEST], {
      businessUri,
    }),
  success: (businessUri, response) =>
    partialsActions.action(types.CHARITIES_LIST[types.SUCCESS], {
      businessUri,
      response,
    }),
  failure: (businessUri, error) =>
    partialsActions.action(types.CHARITIES_LIST[types.FAILURE], {
      businessUri,
      error,
    }),
};

export const loadCharitiesListPage = (businessUri, requiredFields = []) =>
  partialsActions.action(types.LOAD_CHARITIES_LIST_PAGE, {
    businessUri,
    requiredFields,
  });

export * from "./types";
