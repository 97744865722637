import React from "react";
import Routes from "./routes";
import "./css/skeleton.css";
import { Provider } from "react-redux";
import configureStore from "./store/configure-store";
import rootSaga from "./sagas";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

root.render(
  <Provider store={store}>
    <div>
      <Routes />
    </div>
  </Provider>
);
