import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const profile = {
  request: (businessUri) =>
    partialsActions.action(types.PROFILE[types.REQUEST], { businessUri }),
  success: (businessUri, response) =>
    partialsActions.action(types.PROFILE[types.SUCCESS], {
      businessUri,
      response,
    }),
  failure: (businessUri, error) =>
    partialsActions.action(types.PROFILE[types.FAILURE], {
      businessUri,
      error,
    }),
};

export const loadProfilePage = (businessUri, requiredFields = []) =>
  partialsActions.action(types.LOAD_PROFILE_PAGE, {
    businessUri,
    requiredFields,
  });

export * from "./types";
