import Requester from "./requester.service";

// api services
const addresses = {
  loadKlevents: "/pages/{businessId}/walls/{wallId}",
};
export const loadKlevents = function (params) {
  let businessId = params.businessId;
  let wallId = params.wallId;
  let req = new Requester();
  let link = addresses.loadKlevents
    .replace("{businessId}", businessId)
    .replace("{wallId}", wallId);
  return req.get(link).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.log("KLEVENTS REDUCER FAILURE");

      return { error };
    }
  );
};
