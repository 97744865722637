import * as actions from "../../actions";

export default (state = [], action) => {
  switch (action.type) {
    case actions.CONTRIBUTION[actions.FAILURE]:
      console.log("CONTRIBUTION ERROR");
      break;
    case actions.CONTRIBUTION[actions.SUCCESS]:
      if (
        typeof state.contribution === "undefined" &&
        action.response.successfull === true
      ) {
        return Object.assign({}, state, { contribution: action.response.body });
      }
      return state;
    default:
      return state;
  }
};
